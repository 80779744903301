/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Banner, DataList} from '@servicetitan/design-system';
import {Link} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h1: "h1",
    div: "div",
    pre: "pre",
    code: "code",
    hr: "hr",
    strong: "strong"
  }, _provideComponents(), props.components);
  if (!DataList) _missingMdxReference("DataList", false);
  if (!DataList.HeaderCell) _missingMdxReference("DataList.HeaderCell", true);
  return React.createElement(React.Fragment, null, React.createElement(Banner, {
    status: "warning",
    title: "Deprecated"
  }, React.createElement(Link, {
    href: "/components/page"
  }, "Page"), " and ", React.createElement(Link, {
    href: "/components/layout"
  }, "Layout"), " components are recommended for common screen layouts instead of building custom grids."), "\n", React.createElement(_components.p, null, "These sizes are sometimes used in legacy apps. When working on more recent work, the width configurations from the ", React.createElement(_components.a, {
    href: "/components/page"
  }, "Page"), " are the accurate values."), "\n", React.createElement(_components.h1, {
    id: "default-container",
    style: {
      position: "relative"
    }
  }, "Default Container", React.createElement(_components.a, {
    href: "#default-container",
    "aria-label": "default container permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The default container responds to the screen size to have a reasonably sized content container. For content that spans the entire browser window, a container shouldn't be used."), "\n", React.createElement(DataList, {
    className: "DocsTable",
    header: [React.createElement(DataList.HeaderCell, {
      key: 0,
      name: "size",
      content: "Screen Size"
    }), React.createElement(DataList.HeaderCell, {
      key: 1,
      name: "width",
      content: "Container Width"
    })],
    items: [{
      size: 'Less than 768px',
      width: 'Full Width with 16px Gutters'
    }, {
      size: 'Greater than 768px ',
      width: '719px Wide Centered'
    }, {
      size: 'Greater than 992px',
      width: '927px Wide Centered'
    }, {
      size: 'Greater than 1200px',
      width: '1199px Wide Centered'
    }]
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "scaled: true\n---\n<Container className=\"bg-neutral-20 p-2\">\n\tPage content goes here\n</Container>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "full-documentation",
    style: {
      position: "relative"
    }
  }, "Full Documentation", React.createElement(_components.a, {
    href: "#full-documentation",
    "aria-label": "full documentation permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Our ", React.createElement(_components.strong, null, "Container"), " has been modified from the ", React.createElement(_components.a, {
    href: "http://react.semantic-ui.com/collections/grid/"
  }, "Semantic UI"), " grid to follow our ", React.createElement(_components.a, {
    href: "/foundations/spacing"
  }, "Spacing"), " rules."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Container } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
